.loading-icon {
    color: teal;
    font-size: 2rem;
    animation: animate 2s infinite;
  }
  
  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }
  